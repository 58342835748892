export function firebaseconfig() {
    const firebaseConfig = {
        apiKey: "AIzaSyCFl37E3GSFOOPBiuvjuFPUWtrx3P89tPs",
        authDomain: "pmcivilengineer-2788c.firebaseapp.com",
        databaseURL: "https://pmcivilengineer-2788c.firebaseio.com",
        projectId: "pmcivilengineer-2788c",
        storageBucket: "pmcivilengineer-2788c.appspot.com",
        messagingSenderId: "360982480534",
        appId: "1:360982480534:web:f051f3e500e3c7f79e64b7",
        measurementId: "G-YPC9F5395W"
    }
    return firebaseConfig;

}

